<template>
  <div class="renew">
    <div class="renew-header">
      <el-button @click="addRenewWrap = true">添加</el-button>
      <el-button @click="syncTime">同步上新时间</el-button>
      <el-dialog title="重新上新" :visible.sync="addRenewWrap" width="35%">
        <el-form ref="form" label-width="80px">
          <el-form-item label="名称">
            <el-input v-model="renewForm.renewName"></el-input>
          </el-form-item>
          <el-form-item label="上新日期">
            <el-date-picker
              v-model="renewForm.renewDate"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
				  <el-button @click="addRenewWrap = false">取 消</el-button>
				  <el-button type="primary" @click="submitRenew" v-if="add">提 交</el-button>
				  <el-button type="primary" @click="editRenew" v-if="!add">编 辑</el-button>
			  </span>
      </el-dialog>
    </div>
    <div class="renew-content">
      <div class="add-goods-renew-list">
        <el-table :data="renewData">
          <el-table-column prop="renewid" label="id"></el-table-column>
          <el-table-column prop="renewName" label="名称"></el-table-column>
          <el-table-column prop="newDate" label="上新日期">
            <template v-slot="{row}">
              {{ row.renewDate|formatDateStr("yyyy-MM-dd") }}
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="{row}">
<!--              <el-button type="primary" plain @click="handleEdit('edit',row)">编辑</el-button>-->
              <el-button type="primary" plain @click="handleEdit('bind',row)">绑定商品</el-button>
              <!--              <el-button type="primary" plain @click="handleEdit('del',row)">删除</el-button>-->
            </template>
          </el-table-column>
        </el-table>
        <div ref="drawer-footer" class="renew-data-footer" v-if="renewData.length > 0">
          <el-pagination :key="1" :current-page="renewDataPageInfo.page" :page-size="renewDataPageInfo.pagesize"
                         :page-sizes="[10, 20, 50, 100, 200]" layout="total,sizes, prev, pager, next, jumper"
                         :total="renewDataPageInfo.total"
                         @size-change="handleRenewRenewSizeChange"
                         @current-change="handleChangeCurrentPage"
                         background />
        </div>
      </div>
      <div class="add-goods-renew-detail">
        <el-drawer
          title="添加重新上新"
          :visible.sync="addRenewDetailWrap"
          direction="rtl"
          size="45%">
          <div>
            <el-form ref="form" label-width="80px" :inline="true">
              <el-form-item label="商品编号">
                <el-input type="textarea" :rows="5" v-model="addRenewItemno"></el-input>
              </el-form-item>
              <el-button type="primary" @click="submitRenewDetail">提 交</el-button>
            </el-form>
          </div>
          <div class="drawer-content">
            <el-table :data="goodsRenewDetail" height="650">
              <el-table-column prop="" label="" min-width="80"></el-table-column>
              <el-table-column prop="itemNo" label="商品编号" min-width="200"></el-table-column>
              <el-table-column prop="itemName" label="商品名称" min-width="200"></el-table-column>
              <el-table-column prop="base_price" label="操作" min-width="80">
                <template slot-scope="{row}">
                  <el-button type="primary" plain @click="handleDelete(row)">删除</el-button>
                  <!--              <el-button type="primary" plain @click="handleEdit('del',row)">删除</el-button>-->
                </template>
              </el-table-column>
            </el-table>
            <div ref="drawer-footer" style="padding-top: 10px" v-if="goodsRenewDetail.length > 0">
              <el-pagination :key="2" :current-page="renewDeteilPageInfo.page" :page-size="renewDeteilPageInfo.pagesize"
                             :page-sizes="[10, 20, 50, 100, 200]" layout="total,sizes, prev, pager, next, jumper"
                             :total="renewDeteilPageInfo.total"
                             @size-change="handleRenewDeteilSiseChange"
                             @current-change="handleRenewDeteilPageInfoCurrent"
                             background />
            </div>
          </div>
        </el-drawer>
      </div>
    </div>
  </div>
</template>

<script>
import common from "@/common/mixins/common.js";

export default {
  name: "index",
  mixins: [common],
  data() {
    return {
      addRenewWrap: false,
      add:true,
      renewForm: {
        renewName: "",
        renewDate: ""
      },
      renewData: [],
      renewDataPageInfo: {
        total: 0,
        page: 1,
        pagesize: 10
      },
      addRenewDetailWrap: false,
      goodsRenewDetail: [],
      renewDeteilPageInfo: {
        total: 0,
        page: 1,
        pagesize: 10
      },
      rowInfo:{
        renewid:0
      },
      addRenewItemno:""
    };
  },
  created() {
    this.getGoodsRenew();
  },
  methods: {
    submitRenew() {
      let params = {};

      params.date = (this.renewForm.renewDate - 0) / 1000;
      params.name = this.renewForm.renewName;

      this.$axios.post("/a1/goods/renew", params).then(() => {
        this.$message.success("添加成功");
        this.addRenewWrap = false;
        this.getGoodsRenew()
      }).catch(err => {
        this.$message.error(err.response.data.message)
      });
    },
    editRenew(){

      let params = {}

      params.date = (this.renewForm.renewDate - 0) / 1000;
      params.name = this.renewForm.renewName;
      params.renewid = this.rowInfo.renewid

      this.$axios.put('/a1/goods/renew',params).then(() =>{
        this.$message.success("修改成功");
        this.addRenewWrap = false;
        this.getGoodsRenew()
      }).catch(err => {
        this.$message.error(err.response.data.message)
      });

    },
    submitRenewDetail(){

      let arr = []
      this.addRenewItemno.split("\n").forEach(item => {

        if (item){
          arr.push(item.trim())
        }
      })

      let params = {
        itemNo:arr,
        renewid:this.rowInfo.renewid
      }
      console.log(params);
      this.$axios.post('/a1/goods/renew/detail',params).then(() =>{
        this.$message.success("绑定成功")
        this.getGoodsRenewDetail()
      }).catch(err => {
        this.$message.error(err.response.data.message)
      })

    },
    getGoodsRenew() {

      let params = {
        page: this.renewDataPageInfo.page,
        pagesize: this.renewDataPageInfo.pagesize
      };

      this.$axios.get("/a1/goods/renew", { params: params }).then(res => {

        const data = res.data.data;

        this.renewData = data.data;
        this.renewDataPageInfo.total = data.total;

      });
    },
    getGoodsRenewDetail(){

      let params = {
        page:this.renewDeteilPageInfo.page,
        pagesize:this.renewDeteilPageInfo.pagesize,
        renewid:this.rowInfo.renewid
      }

      this.$axios.get('/a1/goods/renew/detail',{params:params}).then(res => {

        const data = res.data.data
        this.goodsRenewDetail = data.data
        this.renewDeteilPageInfo.total = data.total

      })

    },
    //改变条数
    handleRenewRenewSizeChange(val) {
      this.renewDataPageInfo.pagesize = val
      this.getGoodsRenew()
    },
    //翻页
    handleChangeCurrentPage(val) {
      this.renewDataPageInfo.page = val
      this.getGoodsRenew()
    },
    //改变条数
    handleRenewDeteilSiseChange(val) {
      this.renewDeteilPageInfo.pagesize = val
      this.getGoodsRenewDetail()
    },
    //翻页
    handleRenewDeteilPageInfoCurrent(val) {
      this.renewDeteilPageInfo.page = val
      this.getGoodsRenewDetail()
    },

    handleEdit(type, row) {
      switch (type) {
        case "edit":
          this.addRenewWrap = true
          this.renewForm.renewName = row.renewName
          this.renewForm.renewDate = new Date(row.renewDate*1000)
          this.add = false
          break;
        case "bind":
          this.addRenewDetailWrap = true;
          this.rowInfo.renewid = row.renewid
          this.getGoodsRenewDetail()
          break;
        case "del":
          break;
      }
    },
    handleDelete(row) {
      let params = {
        id : row.id
      }
      this.$axios.delete("/a1/goods/renew/detail",{params:params}).then(() =>{
        this.$message.success("删除成功")
        this.getGoodsRenewDetail()
      })

    },
    syncTime(){
      this.$axios.get('/a1/clear/SyncGoodsTime').then(() =>{
        this.$message.success("已开始同步，同步时间需要一分钟左右")
      })

    }
  }
};
</script>

<style scoped lang="scss">
.renew {
  padding: 10px;

  .renew-header {

  }

  .renew-content {
    padding-top: 10px;
    .add-goods-renew-list {
      .renew-data-footer {
        padding-top: 10px;
      }
    }
  }
}
</style>
